import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserReservations } from '../../../api/api';

export const fetchUserReservations = createAsyncThunk('myReservations/fetchUserReservations', async () => {
    const response = await getUserReservations();
    return response.data;
});

const myReservationSlice = createSlice({
    name: 'myReservationSlice',
    initialState: {
        reservations: [],
        loading: false,
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserReservations.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserReservations.fulfilled, (state, action) => {
                state.loading = false;
                state.reservations = action.payload;
            })
            .addCase(fetchUserReservations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default myReservationSlice.reducer;
