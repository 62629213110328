import dayjs from "dayjs";

export const formatSelectedTime = (selectedTimeSlots, customText = 'Выбранное время') => {
    if (!selectedTimeSlots || selectedTimeSlots.length === 0) return "";

    let formattedTime = [];
    let rangeStart = null;
    let rangeEnd = null;

    for (let i = 0; i < selectedTimeSlots.length; i++) {
        const [currentStart, currentEnd] = selectedTimeSlots[i].split('-');
        const nextSlot = selectedTimeSlots[i + 1];
        const [nextStart] = nextSlot ? nextSlot.split('-') : [];

        if (!rangeStart) {
            rangeStart = currentStart;
            rangeEnd = currentEnd;
        }

        const currentEndTime = dayjs(currentEnd, 'HH:mm');
        const nextStartTime = nextStart ? dayjs(nextStart, 'HH:mm') : null;
        if (nextStartTime && currentEndTime.isSame(nextStartTime)) {
            rangeEnd = nextSlot.split('-')[1];
        } else {
            formattedTime.push(`${rangeStart} - ${rangeEnd}`);
            rangeStart = null;
            rangeEnd = null;
        }
    }

    return `${customText}: ${formattedTime.join(', ')}`;
};
