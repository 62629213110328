import React, { useState } from 'react';
import { Box, Button, Container, MenuItem, Select, Typography, Snackbar, Alert } from '@mui/material';
import { getReport } from "../../../api/api";

const ReportDownloadScreen = () => {
    const [reportPeriod, setReportPeriod] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    const periods = {
        today: 0,
        '3days': 3,
        '10days': 10,
        '30days': 30,
    };

    const handlePeriodChange = (event) => {
        setReportPeriod(event.target.value);
    };

    const handleDownloadReport = async () => {
        if (reportPeriod) {
            const periodDays = periods[reportPeriod];
            try {
                const response = await getReport(periodDays);

                if (response?.data?.success) {
                    const fileLinkToOpen = response.data.data;

                    if (window.Telegram && window.Telegram.WebApp) {
                        window.Telegram.WebApp.openLink(fileLinkToOpen);
                    } else {
                        window.location.href = fileLinkToOpen;
                    }

                    setSnackbarMessage('Отчет успешно загружен!');
                    setSnackbarSeverity('success');
                } else {
                    setSnackbarMessage(response.data?.error?.message || 'Неизвестная ошибка');
                    setSnackbarSeverity('error');
                }
            } catch (error) {
                setSnackbarMessage('За выбранный период нет данных для отчета');
                setSnackbarSeverity('error');
            } finally {
                setSnackbarOpen(true);
            }
        } else {
            setSnackbarMessage('Пожалуйста, выберите период отчета');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '20px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                <Typography variant="body1">Выберите период для отчета:</Typography>
                <Select
                    value={reportPeriod}
                    onChange={handlePeriodChange}
                    displayEmpty
                    fullWidth
                    sx={{ maxWidth: '300px' }}
                >
                    <MenuItem value="" disabled>Выберите период</MenuItem>
                    <MenuItem value="today">Сегодня</MenuItem>
                    <MenuItem value="3days">За последние 3 дня</MenuItem>
                    <MenuItem value="10days">За последние 10 дней</MenuItem>
                    <MenuItem value="30days">За последние 30 дней</MenuItem>
                </Select>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadReport}
                    sx={{ maxWidth: '300px', mt: 2 }}
                >
                    Скачать отчет
                </Button>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1500}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                    sx: {
                        backgroundColor: '#fff',
                        color: '#333',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                        padding: '8px 16px',
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ReportDownloadScreen;
