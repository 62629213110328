import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFilteredUserList } from "../../../api/api";

export const fetchUserList = createAsyncThunk(
    'usersList/fetchUsersList',
    async (filter = {}, { rejectWithValue }) => {
        try {
            const data = await fetchFilteredUserList(filter);
            return data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error fetching user list');
        }
    }
);

const usersListSlice = createSlice({
    name: 'usersList',
    initialState: {
        userList: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserList.fulfilled, (state, action) => {
                state.loading = false;
                state.userList = action.payload;
            })
            .addCase(fetchUserList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default usersListSlice.reducer;
