import React from 'react';
import { motion } from 'framer-motion';

const AnimatedBox = ({ children, delay = 0, duration = 0.8, scaleStart = 0.8, ...rest }) => {
    const variants = {
        hidden: { opacity: 0, scale: scaleStart },
        visible: { opacity: 1, scale: 1, transition: { duration, delay } },
    };

    return (
        <motion.div initial="hidden" animate="visible" variants={variants} {...rest}>
            {children}
        </motion.div>
    );
};

export default AnimatedBox;
