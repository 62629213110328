import React from 'react';
import { Container, Typography } from '@mui/material';

const AddRoomScreen = () => (
    <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '20px' }}>
        <Typography variant="h6">Добавить помещение</Typography>
    </Container>
);

export default AddRoomScreen;
