import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getProducts} from "../../../api/api";

export const fetchAllProducts = createAsyncThunk(
    'products/fetchAllProducts',
    async (_, { getState }) => {
        const { auth: { group_id } } = getState();
        const data = await getProducts(group_id);
        return data;
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        loading: false,
        error: null,
    },
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload;
            })
            .addCase(fetchAllProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { setProducts } = productsSlice.actions;

export default productsSlice.reducer;
