import React, { useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Skeleton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Card,
    CardContent,
    CircularProgress
} from '@mui/material';
import dayjs from "dayjs";
import { formatSelectedTime } from "../../utils/formatSelectedTime";
import { cancelReservation, fetchFilteredUserList } from "../../api/api";

const TimeSlotSelector = ({
                              selectedTimeSlots,
                              setSelectedTimeSlots,
                              roomReservations,
                              selectedRooms,
                              loading,
                              selectedDate,
                              reservationData,
                              reservations,
                              openSnackbar,
                          }) => {
    const [isLoadingDelayed, setIsLoadingDelayed] = React.useState(true);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [selectedReservations, setSelectedReservations] = React.useState([]);
    const [reservationUsers, setReservationUsers] = React.useState([]);
    const [clickedTimeSlot, setClickedTimeSlot] = React.useState(null);
    const [isIntercepting, setIsIntercepting] = React.useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoadingDelayed(false);
        }, 1000);
        return () => clearTimeout(timeout);
    }, [loading]);

    const timeSlotToReservation = React.useMemo(() => {
        const mapping = {};
        reservations.forEach((reservation) => {
            reservation.reservation_time.forEach((timeSlot) => {
                if (!mapping[timeSlot]) {
                    mapping[timeSlot] = [];
                }
                mapping[timeSlot].push(reservation);
            });
        });
        return mapping;
    }, [reservations]);

    const handleTimeSlotClick = (timeInterval) => {
        if (selectedTimeSlots.includes(timeInterval)) {
            setSelectedTimeSlots(selectedTimeSlots.filter((slot) => slot !== timeInterval));
        } else {
            setSelectedTimeSlots([...selectedTimeSlots, timeInterval].sort());
        }
    };

    const handleUnavailableTimeSlotClick = async (timeInterval) => {
        setClickedTimeSlot(timeInterval);
        const reservationsAtTime = timeSlotToReservation[timeInterval];
        if (reservationsAtTime && reservationsAtTime.length > 0) {
            setSelectedReservations(reservationsAtTime);
            const userIds = [...new Set(reservationsAtTime.map(reservation => reservation.created_by))];

            try {
                const userInfoResponse = await fetchFilteredUserList({ _id: userIds });
                const userInfoArray = userInfoResponse.data;
                if (Array.isArray(userInfoArray) && userInfoArray.length > 0) {
                    setReservationUsers(userInfoArray);
                    setDialogOpen(true);
                } else {
                    openSnackbar('Не удалось получить информацию о пользователях.', 'error');
                }
            } catch (error) {
                openSnackbar('Ошибка при получении информации о пользователях.', 'error');
            }
        }
    };

    const handleIntercept = async (reservationId, timeSlot) => {
        setIsIntercepting(true);
        try {
            await cancelReservation(reservationId, timeSlot);
            setDialogOpen(false);
            openSnackbar('Ваш запрос на перехват успешно отправлен создателю мероприятия!', 'success');
        } catch (error) {
            openSnackbar('Не удалось отправить запрос на перехват. Пожалуйста, попробуйте позже.', 'error');
        } finally {
            setIsIntercepting(false);
        }
    };
    const isTimeSlotUnavailable = (timeInterval) => {
        const roomsToCheck = reservationData?.product_id
            ? [{ id: reservationData?.product_id }]
            : selectedRooms || [];

        if (!Array.isArray(roomsToCheck)) {
            console.error('roomsToCheck is not an array:', roomsToCheck);
            return false;
        }

        const validRooms = roomsToCheck.filter((room, index) => {
            if (!room) {
                console.warn(`Encountered undefined or null room at index ${index} in roomsToCheck`);
                return false;
            }
            if (!room.id) {
                console.warn(`Room object missing id at index ${index}:`, room);
                return false;
            }
            return true;
        });

        if (validRooms.length === 0) {
            console.warn('No valid rooms to check for time slot availability.');
            return false;
        }

        return validRooms.every((room) => {
            const roomReservedTimeSlots = roomReservations[room.id] || [];
            return roomReservedTimeSlots.includes(timeInterval);
        });
    };

    const renderTimeSlots = () => {
        const timeSlots = [];
        const startHour = 8;
        const endHour = 20;
        const currentDate = dayjs().format('YYYY-MM-DD');
        const isToday = dayjs(selectedDate).format('YYYY-MM-DD') === currentDate;
        const now = dayjs();

        for (let hour = startHour; hour < endHour; hour++) {
            for (let minutes of [0, 30]) {
                const startTime = dayjs(selectedDate).hour(hour).minute(minutes).second(0);
                const endTime = startTime.add(30, 'minute');
                const timeInterval = `${startTime.format('HH:mm')}-${endTime.format('HH:mm')}`;

                const isUnavailable = isTimeSlotUnavailable(timeInterval);
                const isSelected = selectedTimeSlots.includes(timeInterval);
                const isPast = isToday && now.isAfter(startTime);

                timeSlots.push(
                    <Grid item xs={4} sm={3} md={2} key={timeInterval}>
                        <Box
                            onClick={() => {
                                if (isUnavailable || isPast) {
                                    handleUnavailableTimeSlotClick(timeInterval);
                                } else {
                                    handleTimeSlotClick(timeInterval);
                                }
                            }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                width: '100px',
                                height: '50px',
                                cursor: 'pointer',
                                backgroundColor: isUnavailable || isPast ? '#f0f0f0' : isSelected ? '#e3f2fd' : 'transparent',
                                border: '1px solid',
                                borderColor: isUnavailable || isPast ? '#bdbdbd' : '#90caf9',
                                borderRadius: '8px',
                                color: isUnavailable || isPast ? '#bdbdbd' : '#000',
                                transition: 'background-color 0.3s',
                                margin: '3px',
                            }}
                        >
                            <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                {timeInterval}
                            </Typography>
                        </Box>
                    </Grid>
                );
            }
        }
        return timeSlots;
    };

    return (
        <>
            <Box>
                <Box
                    sx={{
                        maxHeight: { xs: '31dvh' },
                        border: '1px solid #90caf9',
                        borderRadius: '8px',
                        overflowY: 'auto',
                        padding: '0.2em',
                    }}
                >
                    {(loading || isLoadingDelayed) ? (
                        <Grid container spacing={1} justifyContent="center">
                            {Array.from(new Array(10)).map((_, index) => (
                                <Grid item xs={4} sm={3} md={2} key={index}>
                                    <Skeleton variant="rectangular" width={100} height={50} sx={{ margin: '3px', borderRadius: '6px' }} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Grid container spacing={1} justifyContent="center">
                            {renderTimeSlots()}
                        </Grid>
                    )}
                </Box>
                <Typography variant="body2" sx={{ marginTop: '0.2em', textAlign: 'center' }}>
                    {formatSelectedTime(selectedTimeSlots)}
                </Typography>
                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
                    <DialogTitle>Детали резервирования</DialogTitle>
                    <DialogContent
                        sx={{
                            padding: '1em',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5em',
                        }}>
                        {selectedReservations && selectedReservations.length > 0 ? (
                            selectedReservations.map((reservation) => {
                                const user = reservationUsers.find(user => user?.id === reservation.created_by);
                                const room = (selectedRooms?.length ? selectedRooms : Array.isArray(reservationData) ? reservationData : [reservationData])
                                        .find(room => room?.id === reservation.product_id) ||
                                    (Array.isArray(reservationData) ? reservationData.find(data => data.product?.id === reservation.product_id)?.product : reservationData.product);
                                const isPast = dayjs(reservation.data).isBefore(dayjs());
                                return (
                                    <Card
                                        key={reservation.id}
                                        sx={{
                                            cursor: 'pointer',
                                            boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)',
                                            borderRadius: '12px',
                                            marginTop: '0.2em',
                                        }}
                                    >
                                        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography variant="subtitle1" sx={{ lineHeight: 1, color: isPast ? 'text.secondary' : 'text.primary' }}>
                                                    Мероприятие: {reservation.label || 'Без названия'}
                                                </Typography>
                                                <Typography variant="body2" gutterBottom color={isPast ? 'text.secondary' : 'text.primary'}>
                                                    Зал: {room ? room.label : 'Не указан'}
                                                </Typography>
                                                <Typography variant="body2" gutterBottom color={isPast ? 'text.secondary' : 'text.primary'}>
                                                    Забронировал: {user ? `${user.first_name} ${user.last_name}` : 'Неизвестный пользователь'}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{
                                                borderRadius: '0 0 12px 12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                            onClick={() => handleIntercept(reservation.id, clickedTimeSlot)}
                                            disabled={isIntercepting}
                                        >
                                            {isIntercepting ? <CircularProgress size={24} color="inherit" /> : 'Перехватить'}
                                        </Button>
                                    </Card>
                                );
                            })
                        ) : (
                            <DialogContentText>Нет информации о мероприятиях.</DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Закрыть</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};

export default TimeSlotSelector;
