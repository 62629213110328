import React, { useState } from 'react';
import { Box, Skeleton } from '@mui/material';

const Slide = ({ src, alt }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    return (
        <Box
            sx={{
                borderRadius: '12px 12px 0 0',
                overflow: 'hidden',
                width: '100%',
                height: { xs: '250px', sm: '400px' },
                position: 'relative',
            }}
        >
            {!imageLoaded && (
                <Skeleton
                    variant="rectangular"
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '12px 12px 0 0',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
            )}
            <img
                src={src}
                alt={alt}
                onLoad={() => setImageLoaded(true)}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    display: imageLoaded ? 'block' : 'none',
                }}
            />
        </Box>
    );
};

export default Slide;
