import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

export const setAuthToken = (token) => {
    apiClient.interceptors.request.use(
        (config) => {
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

export const login = (initData) => {
    return apiClient.post('/login', {
        initData: initData
    });
};

export const getProducts = async (groupId) => {
    const response = await apiClient.get(`/product/${groupId}`);
    return response.data.data;
};

export const makeReservation = async (reservationData) => {
    try {
        const response = await apiClient.post('/reservation', reservationData);
        return response.data;
    } catch (error) {
        throw new Error('Failed to make a reservation');
    }
};

export const editReservation = async (reservationData) => {
    try {
        const response = await apiClient.patch('/reservation', reservationData);
        return response.data;
    } catch (error) {
        throw new Error('Failed to make a reservation');
    }
};

export const checkReservation = async (roomIds, date) => {
    try {
        const response = await apiClient.post(
            `/reservation/get?data=${date}`,
            roomIds,
        );
        return response.data;
    } catch (error) {
        throw new Error('Failed to check reservation');
    }
};

export const getUserReservations = async () => {
    try {
        const response = await apiClient.get('/reservation/me');
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch user reservations');
    }
};

export const deleteReservation = async (reservationId) => {
    try {
        const response = await apiClient.delete(`/reservation/${reservationId}`);
        return response.data;
    } catch (error) {
        throw new Error('Не удалось удалить резервацию');
    }
};

export const addUser = async (userData) => {
    try {
        const response = await apiClient.post('/user', userData);
        return response.data;
    } catch (error) {
        throw new Error('Failed to add user');
    }
};

export const fetchFilteredUserList = async (filter = {}) => {
    try {
        const payload = { filter };
        const response = await apiClient.post('/user/search', payload);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch filtered user list:', error);
        throw new Error('Failed to fetch filtered user list');
    }
};

export const deleteUser = async (userId) => {
    try {
        const response = await apiClient.delete(`/user/${userId}`);
        return response.data;
    } catch (error) {
        throw new Error('Не удалось удалить пользователя');
    }
};

export const uploadFiles = (product_id, files, onUploadProgress) => {
    const formData = new FormData();
    formData.append('product_id', product_id);
    files.forEach((file) => {
        formData.append('file', file);
    });

    return apiClient.post(`/file/${product_id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    });
};

export const cancelReservation = async (reservationId, time) => {
    try {
        const response = await apiClient.post(`/reservation/request_cancel/${reservationId}`, null, {
            params: { time },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to cancel reservation');
    }
};

export const getReport = async (periodDays) => {
    try {
        const response = await apiClient.post(
            `/report?period_days=${periodDays}`,
            null
        );
        return response;
    } catch (error) {
        console.error('Failed to download report:', error);
        throw new Error('Не удалось скачать отчет');
    }
};




