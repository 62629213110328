import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteButton = ({ onClick }) => {
    return (
        <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={onClick}
            sx={{
                fontSize: '0.875rem',
                padding: '6px 16px',
                borderRadius: '8px',
            }}
        >
            Удалить
        </Button>
    );
};

export default DeleteButton;
