import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { checkReservation } from "../../../api/api";

export const fetchReservations = createAsyncThunk(
    'reservations/fetchReservations',
    async ({ roomIds, date }) => {
        const data = await checkReservation(roomIds, date);
        return data.data;
    }
);

const reservationSlice = createSlice({
    name: 'reservations',
    initialState: {
        reservations: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReservations.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchReservations.fulfilled, (state, action) => {
                state.loading = false;
                state.reservations = action.payload;
            })
            .addCase(fetchReservations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default reservationSlice.reducer;
